@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Roboto+Mono:wght@300;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

body {
  background-color: white;
  color: black;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.smoke canvas {
  width: 100% !important;
  height: 100% !important;
}

.smoke div {
  opacity: 0.6;
}

.menu {
  width: 28rem;
}

.navItem:hover > div {
  background-color: #4087f7;
}

.navItem:hover > div svg {
  fill: white;
  color: white;
}

.navItem-item svg {
  fill: #4087f7;
  color: #4087f7;
}

.navItem:hover > div > div h4 {
  color: white;
}

.navItem:hover > div > div h3 {
  color: #e5e5e5;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-snap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-snap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

model-viewer::part(default-progress-bar) {
  display: none;
}

.logoParent img {
  height: 100%;
}

.logoParent div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px !important
}

.gridBackground {
  background: white;
  background-image: radial-gradient(#d1d1d1 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
}

.halfBackground {
  background: white;
}

.halfBackgroundReverse {
  background: white;
}

@media (min-width: 768px) {
  .halfBackground {
    background:linear-gradient(to bottom, #f3f4f6 0, #f3f4f6 33%, #4087f7 33%, #4087f7 90%, #ffffff 90%, #ffffff 100%);
  }

  .halfBackgroundReverse {
    background: linear-gradient(180deg, #ffffff 50%, #4087f7 50%);
  }
}
